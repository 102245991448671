<template>
  <moe-page title="短信管理">
    <!-- 筛选信息 -->
    <moe-inquire @search="messageSearch">
      <el-form-item label="短信模版">
        <el-input v-model.trim="messageParams.title" placeholder="输入短信模版" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="发送对象">
        <moe-select type="sendTargetList" v-model="messageParams.sendTarget" placeholder="选择发送对象" clearable />
      </el-form-item>
      <el-form-item label="发送时间">
        <el-date-picker
          style="width: 100%;"
          placement="bottom-start"
          v-model="datetime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([messageParams.startTime, messageParams.endTime] = datetime || ['', ''])" />
      </el-form-item>
    </moe-inquire>

    <!-- 推送信息 -->
    <moe-table ref="messageTableRef" url="/shop/sms/pageList" :params="messageParams" :numberShow="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/pushManage/message/add')">短信添加</el-button>
      </template>

      <el-table-column prop="title" label="短信模版" min-width="150" show-overflow-tooltip />
      <el-table-column prop="content" label="模版内容" min-width="150" show-overflow-tooltip />
      <el-table-column prop="sendTarget" label="发送对象" min-width="150">
        <template slot-scope="{ row }">{{ $moe_format.getSendTargetList(row.sendTarget) }}</template>
      </el-table-column>
      <el-table-column prop="pushTime" label="发送时间" min-width="150" />
      <el-table-column prop="sendStatus" label="状态" min-width="150">
        <template slot-scope="{ row }">{{ $moe_format.getSendStatusList(row.sendStatus) }}</template>
      </el-table-column>
      <el-table-column prop="createUserName" label="操作人" min-width="150" />

      <el-table-column label="操作" fixed="right" min-width="150">
        <div class="moe-table_btns" slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/pushManage/message/detail`, { id: row.id })">查看详情</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'PushMessageList',
  data() {
    return {
      messageParams: {
        pageNum: 1,
        pageSize: 10,
        title: '',
        sendTarget: '',
        startTime: '',
        endTime: '',
      },
      datetime: [],
    }
  },
  methods: {
    messageSearch(isSearch) {
      if (!isSearch) {
        this.messageParams = {
          pageNum: 1,
          pageSize: 10,
          title: '',
          sendTarget: '',
          startTime: '',
          endTime: '',
        }

        this.datetime = [];
      }

      this.$refs['messageTableRef'].searchData();
    }
  }
}
</script>